import React from 'react';
import './SocialLink.scss';

const LINKEDIN_TYPE = 'linkedin';
const TWITTER_TYPE = 'twitter';
const INSTAGRAM_TYPE = 'instagram';

export const SocialLink = (props) => {
  const {
    socialLink,
    theme = 'light',
    size = '',
  } = props;
  const {
    type,
    url,
  } = socialLink;
  const typeClass = type.replace(/_/g, '-');
  switch (type) {
    case LINKEDIN_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 19h-2.886v-4.27c0-1.019-.019-2.33-1.5-2.33-1.502 0-1.731 1.11-1.731 2.256V19H10v-8.782h2.767v1.2h.04c.384-.69 1.326-1.418 2.73-1.418C18.46 10 19 11.818 19 14.184V19zM6.5 8a1.5 1.5 0 1 1-.002-2.999A1.5 1.5 0 0 1 6.501 8zM5 19h3v-9H5v9zm7-19C5.373 0 0 5.373 0 12c0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" fill="#414042" fillRule="evenodd" />
          </svg>
        </a>
      );
    case TWITTER_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.344 9.259c.005.118.008.237.008.357 0 3.643-2.773 7.845-7.845 7.845a7.804 7.804 0 0 1-4.227-1.239 5.533 5.533 0 0 0 4.082-1.142 2.76 2.76 0 0 1-2.576-1.915 2.767 2.767 0 0 0 1.246-.047 2.76 2.76 0 0 1-2.212-2.704v-.034c.372.206.797.33 1.249.345a2.756 2.756 0 0 1-.853-3.682 7.828 7.828 0 0 0 5.683 2.881 2.757 2.757 0 0 1 4.699-2.515 5.517 5.517 0 0 0 1.75-.669 2.765 2.765 0 0 1-1.212 1.526 5.506 5.506 0 0 0 1.584-.434 5.6 5.6 0 0 1-1.376 1.427M12-.001C5.373 0 0 5.373 0 12c0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12" fill="#414042" fillRule="evenodd" />
          </svg>
        </a>
      );
    case INSTAGRAM_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <path id="a" d="M0 0h24v24H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <path d="M12.353 9.882a2.47 2.47 0 1 0 0 4.942 2.47 2.47 0 0 0 0-4.942" fill="#414042" />
              <path d="M16.137 8.793a.93.93 0 1 1 0-1.86.93.93 0 0 1 0 1.86M12 15.98a3.98 3.98 0 1 1 0-7.958 3.98 3.98 0 0 1 0 7.958m6.041-8.55a2.401 2.401 0 0 0-.58-.89 2.4 2.4 0 0 0-.89-.58c-.274-.106-.684-.232-1.44-.267-.817-.037-1.062-.045-3.131-.045-2.07 0-2.314.008-3.131.045-.756.035-1.166.16-1.44.267a2.4 2.4 0 0 0-.89.58 2.4 2.4 0 0 0-.58.89c-.106.274-.232.684-.267 1.44-.037.817-.045 1.062-.045 3.131 0 2.07.008 2.314.045 3.131.035.756.16 1.166.267 1.44.14.361.309.62.58.89.27.271.529.44.89.58.274.106.684.232 1.44.267.817.037 1.062.045 3.131.045 2.07 0 2.314-.008 3.131-.045.756-.035 1.166-.16 1.44-.267a2.4 2.4 0 0 0 .89-.58c.271-.27.44-.529.58-.89.106-.274.232-.684.267-1.44.037-.817.045-1.062.045-3.131 0-2.07-.008-2.314-.045-3.131-.035-.756-.16-1.166-.267-1.44" fill="#414042" />
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <path d="M19.674 15.183c-.037.822-.168 1.383-.359 1.874a3.784 3.784 0 0 1-.89 1.368c-.43.429-.86.693-1.368.89-.49.191-1.052.322-1.874.36-.823.037-1.086.046-3.183.046-2.097 0-2.36-.01-3.183-.047-.822-.037-1.383-.168-1.874-.359a3.784 3.784 0 0 1-1.368-.89c-.429-.43-.693-.86-.89-1.368-.191-.49-.322-1.052-.36-1.874-.037-.823-.046-1.086-.046-3.183 0-2.097.01-2.36.047-3.183.037-.822.168-1.383.359-1.874.197-.508.461-.939.89-1.368.43-.429.86-.693 1.368-.89.49-.191 1.052-.322 1.874-.36C9.64 4.289 9.903 4.28 12 4.28c2.097 0 2.36.01 3.183.047.822.037 1.383.168 1.874.359.508.197.939.461 1.368.89.429.43.693.86.89 1.368.191.49.322 1.052.36 1.874.037.823.046 1.086.046 3.183 0 2.097-.01 2.36-.047 3.183M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0" fill="#414042" mask="url(#b)" />
            </g>
          </svg>

        </a>
      );
    default:
      return null;
  }
};
