import React from 'react';
import Image from '../components/Image';
import { Breadcrumbs } from '../components/Breadcrumbs';
import './Banner.scss';

export const Banner = (props) => {
  const {
    image,
    title,
    breadcrumbs,
    currentPageCrumbText,
    wordpressUrl,
    hideHeading = false,
    children
  } = props;
  return (
    <section className="banner">
      <Image className="banner-image" image={image} />
      <div className="wrapper">
        {!hideHeading && <h1 dangerouslySetInnerHTML={{__html: title}} />}
        {children}
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          pageTitle={title}
          currentPageCrumbText={currentPageCrumbText}
          wordpressUrl={wordpressUrl}
        />
      </div>
    </section>
  );
}
