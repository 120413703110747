import React from 'react';
import { Link } from 'gatsby';
import { decodeEntities, stripHtmlTags } from '../utils/htmlParse';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './Breadcrumbs.scss';

export const Breadcrumbs = ({ breadcrumbs, currentPageCrumbText, pageTitle, wordpressUrl }) => {
  return (
    <div className="breadcrumbs">
      <Link className="crumb" to="/">Home</Link>
      {breadcrumbs && breadcrumbs.map((crumb, index) => (
        <React.Fragment key={`${crumb.crumbText}--${index}`}>
          {crumb.pageLink ? (
            <Link
              className="crumb"
              key={`${crumb.pageLink}-${index}`}
              to={getPageSlugFromWpLink(crumb.pageLink, wordpressUrl)}
            >
              {decodeEntities(crumb.crumbText)}
            </Link>
          ) : (
            <span className="crumb">{decodeEntities(crumb.crumbText)}</span>
          )}
        </React.Fragment>
      ))}
      <span className="crumb">{decodeEntities(stripHtmlTags(currentPageCrumbText || pageTitle))}</span>
    </div>
  );
}
