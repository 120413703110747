import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { NavItems } from './NavItems';

export const FooterNavigation = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-footer-navigation" }) {
            items {
              title
              type
              url
              classes
              children: wordpress_children {
                title
                type
                url
                classes
              }
            }
          }
        }
      `}
      render={(data) => {
        const { footerNavigation } = data;
        const navCols = [];
        let tempCol = [];
        footerNavigation.items.forEach((item, index) => {
          if (item.classes === 'new-col') {
            navCols.push(tempCol);
            tempCol = [];
            tempCol.push(item);
          } else {
            tempCol.push(item);
          }
          // If we reach the end of the navigation items, push whatever is in
          // tempCols to navCols
          if (index === footerNavigation.items.length - 1) {
            navCols.push(tempCol);
          }
        })
        return (
          <nav className="primary-footer-navigation">
            <div className="nav-cols">
              {navCols.map((columnItems, colIndex) => (
                <div className="nav-col" key={`${columnItems.length}--${colIndex}`}>
                  <NavItems items={columnItems} />
                </div>
              ))}
            </div>
          </nav>
        );
      }}
    />
  );
}
