import React from 'react';
import Helmet from 'react-helmet';
import { Header } from './Header';
import { Footer } from './Footer';
import 'typeface-maven-pro';
import '../sass/global/styles.scss';

const Layout = ({ children, location, className = '' }) => {
  const safVals = [
    'saf',
    'page--architectural-finishes'
  ];
  const isSaf = className.split(' ').some((val) => {
    return safVals.indexOf(val) !== -1;
});
  return (
    <div id="layout" className={className} style={{overflow: 'hidden'}}>
      <Helmet title="Home | Koval" />
      <Header location={location} isSaf={isSaf} />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default Layout
