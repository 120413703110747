import React, { Component } from 'react';
import { Link } from 'gatsby';
import { HeaderNavigation } from './HeaderNavigation';
import primaryLogo from '../images/logo-primary.svg';
import whiteLogo from '../images/logo-white.svg';
import safLogo from '../images/logo-saf.svg';
import './Header.scss';

export class Header extends Component {
  state = {
    navOpen: false,
    scrolled: false,
    scrollTop: 0
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { navOpen: wasActive } = prevState;
    const { navOpen: isActive } = this.state;
    if (!wasActive && isActive) this.lockBody();
    if (wasActive && !isActive) this.unlockBody();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('keydown', this.handleEscKey, false);
  }

  getLogo = () => {
    const { isSaf } = this.props;
    const { scrolled, navOpen } = this.state;
    if (scrolled || navOpen) {
      if (isSaf) {
        return safLogo;
      }
      return primaryLogo;
    }
    return whiteLogo;
  }

  handleEscKey = event => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      this.setState({ navOpen: false });
    }
  };

  lockBody = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({ scrollTop }, () => {
      document.body.style.top = `-${scrollTop}px`;
      document.body.classList.add('nav-open');
    });
  };

  unlockBody = () => {
    const { scrollTop } = this.state;
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    this.setState({ scrollTop: 0 });
  };

  onScroll = () => {
    const { scrolled } = this.state;
    const scrollPos = window.scrollY || document.documentElement.scrollTop || 0;
    if (scrollPos > 80 && !scrolled) {
      this.setState({ scrolled: true });
    }
    if (scrollPos < 80 && scrolled) {
      this.setState({ scrolled: false });
      // If nav is open and we have already scrolled, re-set scrolled to true
      // Using the negative top value that we set on the body
      if (
        document.body.classList.contains('nav-open') &&
        Math.abs(parseInt(document.body.style.top, 10)) > 80
      ) {
        this.setState({ scrolled: true });
      }
    }
  }

  toggleNav = (event) => {
    if (event) event.preventDefault();
    const scrollPos = window.scrollY || document.documentElement.scrollTop || 0;
    const { navOpen: stateNavOpen, scrolled } = this.state;
    if (!stateNavOpen && scrolled) {
      this.setState({ scrolled: false });
    }
    if (stateNavOpen && scrollPos > 80) {
      this.setState({ scrolled: true });
    }
    this.setState({ navOpen: !stateNavOpen });
  }

  onLinkClick = () => {
    this.setState({ navOpen: false });
  }

  render() {
    const { location } = this.props;
    const { scrolled, navOpen } = this.state;
    return (
      <header id="main-header" className={scrolled ? 'scrolled' : ''}>
        <div className="wrapper">
          <Link onClick={this.onLinkClick} className="logo" to="/">
            <img src={this.getLogo()} alt="Company Logo" />
          </Link>
          <button className={`menu-toggle ${navOpen ? 'toggled' : ''}`} type="button" onClick={this.toggleNav} />
          <HeaderNavigation isOpenMobile={navOpen} location={location} onClickCallback={this.onLinkClick} />
        </div>
      </header>
    );
  }
}
