import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import { SocialLink } from './SocialLink';
import primaryLogo from '../images/logo-primary.svg';
import { FooterNavigation } from './FooterNavigation';
import './Footer.scss';

export class Footer extends Component {
  render() {
    const date = new Date();
    const currentYear = date.getFullYear();
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                wordpressUrl
                siteUrl
              }
            }
            siteSettings: wordpressAcfOptions {
              options {
                socialLinks {
                  type
                  url
                }
                address: addressDisplay
                faxNumber
                phoneNumber
                emailAddress
                accreditations {
                  accreditationTitle
                  accreditationText
                }
                tagline
              }
            }
            secondaryFooterNav: wordpressWpApiMenusMenusItems(slug: { eq: "secondary-footer-navigation" }) {
              items {
                title
                type
                url
                classes
              }
            }
          }
        `}
        render={(data) => {
          const { secondaryFooterNav, site, siteSettings } = data;
          const { options } = siteSettings;
          const {
            accreditations = null,
            tagline,
            socialLinks = null,
            address = null,
            faxNumber = null,
            phoneNumber = null,
            emailAddress = null,
          } = options;
          const { siteMetadata } = site;
          const { wordpressUrl, siteUrl } = siteMetadata;
          return (
            <footer id="main-footer">
              <div className="wrapper">
                <div className="footer-topbar">
                  <span className="logo">
                    <img src={primaryLogo} alt="Footer Logo" />
                  </span>
                  <span className="tagline" dangerouslySetInnerHTML={{__html: tagline}} />
                </div>
                <div className="footer-contact">
                  <FooterNavigation />
                  <div className="contact-details">
                    {phoneNumber && (
                      <span className="phone">
                        <a href={`tel:${phoneNumber}`} dangerouslySetInnerHTML={{__html: phoneNumber}} />
                      </span>
                    )}
                    {faxNumber && <span className="fax" dangerouslySetInnerHTML={{__html: faxNumber}} />}
                    {emailAddress && (
                      <span className="email">
                        <a href={`mailto:${emailAddress}`} dangerouslySetInnerHTML={{__html: emailAddress}} />
                      </span>
                    )}
                    {address && <span className="address" dangerouslySetInnerHTML={{__html: address}} />}
                  </div>
                </div>
                {socialLinks && (
                  <div className="social-links">
                    {socialLinks.map((socialLink, socialLinkIndex) => (
                      <React.Fragment key={`${socialLink.type}--${socialLinkIndex}`}>
                        <SocialLink socialLink={socialLink} />
                      </React.Fragment>
                    ))}
                  </div>
                )}
                <div className="disclaimers">
                  <div className="left-box">
                    <div className="copyright">
                      <span className="copyright-text">
                        &copy;
                        {`${currentYear} Swan Group`}
                      </span>
                      <nav className="secondary-footer-navigation">
                        <ul className="nav-items">
                          {secondaryFooterNav.items.map(item => {
                            return (
                              <li className="nav-item" key={item.url}>
                                <Link to={getPageSlugFromWpLink(item.url, wordpressUrl)}>{decodeEntities(item.title)}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </nav>
                    </div>
                    <div className="rego">
                      <span>Builders Registration Number (BRB): 103122</span>
                      <span>ABN: 17 626 745 917</span>
                      <span>ACN Number: 626 745 917</span>
                    </div>
                  </div>
                  {accreditations && (
                    <div className="accreditations">
                      {accreditations.map((accreditation, accreditationIndex) => (
                        <div className="accreditation" key={`${accreditation.accreditationText}--${accreditationIndex}`}>
                          <span>{accreditation.accreditationTitle}</span>
                          <p>{accreditation.accreditationText}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </footer>
          );
        }}
      />
    );
  }
}
