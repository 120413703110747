import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';

export const NavItems = ({ items, onClickCallback = () => {}, location = {} }) => {
  if (items.length === 0) return null;
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              wordpressUrl
            }
          }
        }
      `}
      render={(data) => {
        const { site } = data;
        const { siteMetadata } = site;
        const { wordpressUrl } = siteMetadata;
        return (
          <ul className="nav-items">
            {items.map(item => {
              const pagePath = getPageSlugFromWpLink(item.url, wordpressUrl);
              const isActive = pagePath === location.pathname;
              return (
                <li
                  className={`nav-item ${item.classes} ${item.children ? 'parent' : ''} ${isActive ? 'active' : ''} ${item.url === '#' ? 'non-link' : ''}`}
                  key={item.url}
                >
                  <Link
                    to={getPageSlugFromWpLink(item.url, wordpressUrl)}
                    onClick={item.url === '#' ? (e) => e.preventDefault() : onClickCallback}
                  >
                    {decodeEntities(item.title)}
                  </Link>
                  {item.children && (
                    <ul className="child-nav-items">
                      {item.children.map(childItem => {
                        const childPagePath = getPageSlugFromWpLink(childItem.url, wordpressUrl);
                        const childIsActive = childPagePath === location.pathname;
                        return (
                          <li
                            className={`child-nav-item ${item.classes} ${childIsActive ? 'active' : ''}`}
                            key={childItem.url}
                          >
                            <Link
                              to={getPageSlugFromWpLink(childItem.url, wordpressUrl)}
                              onClick={onClickCallback}
                            >
                              {decodeEntities(childItem.title)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        );
      }}
    />
  )
}
