import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { NavItems } from './NavItems';

export const HeaderNavigation = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          headerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "header-navigation" }) {
            items {
              title
              type
              url
              classes
              children: wordpress_children {
                title
                type
                url
                classes
              }
            }
          }
        }
      `}
      render={(data) => {
        const { headerNavigation } = data;
        const { isOpenMobile, onClickCallback, location } = props;
        return (
          <nav className={`header-navigation ${isOpenMobile ? 'toggled' : ''}`}>
            <NavItems items={headerNavigation.items} onClickCallback={onClickCallback} location={location} />
          </nav>
        );
      }}
    />
  );
}
